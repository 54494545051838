import React from 'react';
import { WebflowAgencyView } from 'views/webflow-agency';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/services/webflow-agency.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';
import aiAssistantMessages from 'constants/translations/ai-assistant.json';

const WebflowAgencyPage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{
                ...messages,
                ...caseStudiesMessages,
                ...aiAssistantMessages,
            }}
        >
            <WebflowAgencyView {...{ faqData }} />
        </Layout>
    );
};

WebflowAgencyPage.propTypes = {
    location: object.isRequired,
};

export default WebflowAgencyPage;

export const Head = () => <SEO tags={TAGS.WEBFLOW_AGENCY} />;

export const pageQuery = graphql`
    query WebflowAgencyPageQuery {
        allContentfulFaq(filter: { slug: { eq: "services/webflow-agency" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
